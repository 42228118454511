import { css } from 'styled-components';

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

/**
 * Converts breakpoint units in px to rem or em
 * @param {Object} breakpoints - an object containing breakpoint names as keys and the width as value
 * @param {number} ratio [16] - size of 1 rem in px. What is your main font-size in px?
 * @param {'rem' | 'em'} unit
 */
function pxToEmOrRem(breakpoints) {
  var ratio = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 16;
  var unit = arguments.length > 2 ? arguments[2] : undefined;
  var newBreakpoints = {};

  for (var key in breakpoints) {
    var point = breakpoints[key];

    if (String(point).includes('px')) {
      newBreakpoints[key] = +(parseInt(point) / ratio) + unit;
      continue;
    }

    newBreakpoints[key] = point;
  }

  return newBreakpoints;
}
/**
 * Converts breakpoint units in px to em 
 * @param {Object} breakpoints - an object containing breakpoint names as keys and the width as value
 * @param {number} ratio [16] - size of 1em in px. What is your main font-size in px?
 */


function pxToEm(breakpoints) {
  var ratio = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 16;
  return pxToEmOrRem(breakpoints, ratio, 'em');
}
/**
 * Converts breakpoint units in px to rem 
 * @param {Object} breakpoints - an object containing breakpoint names as keys and the width as value
 * @param {number} ratio [16] - size of 1rem in px. What is your main font-size in px?
 */

function pxToRem(breakpoints) {
  var ratio = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 16;
  return pxToEmOrRem(breakpoints, ratio, 'rem');
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n          @media (min-width: ", ") {\n            ", "\n          }\n        "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n          @media (max-width: ", ") {\n            ", "\n          }\n        "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    @media (min-width: ", ") and\n      (max-width: ", ") {\n      ", "\n    }\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    @media (min-width: ", ") {\n      ", "\n    }\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    @media (max-width: ", ") {\n      ", "\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
/**
 * Default media breakpoints
 * @type {Object}
 */

var defaultBreakpoints = {
  huge: '1440px',
  large: '1170px',
  medium: '768px',
  small: '450px'
};

function getSizeFromBreakpoint(breakpointValue) {
  var breakpoints = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (breakpoints[breakpointValue]) {
    return breakpoints[breakpointValue];
  } else if (parseInt(breakpointValue)) {
    return breakpointValue;
  } else {
    console.error('styled-media-query: No valid breakpoint or size specified for media.');
    return '0';
  }
}
/**
 * Media query generator
 * @param {Object} breakpoints - Map labels to breakpoint sizes
 * @return {Object} - Media generators for each breakpoint
 */


function generateMedia() {
  var breakpoints = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultBreakpoints;

  var lessThan = function lessThan(breakpoint) {
    return function () {
      return css(_templateObject(), getSizeFromBreakpoint(breakpoint, breakpoints), css.apply(void 0, arguments));
    };
  };

  var greaterThan = function greaterThan(breakpoint) {
    return function () {
      return css(_templateObject2(), getSizeFromBreakpoint(breakpoint, breakpoints), css.apply(void 0, arguments));
    };
  };

  var between = function between(firstBreakpoint, secondBreakpoint) {
    return function () {
      return css(_templateObject3(), getSizeFromBreakpoint(firstBreakpoint, breakpoints), getSizeFromBreakpoint(secondBreakpoint, breakpoints), css.apply(void 0, arguments));
    };
  };

  var oldStyle = Object.keys(breakpoints).reduce(function (acc, label) {
    var size = breakpoints[label];

    acc.to[label] = function () {
      console.warn("styled-media-query: Use media.lessThan('".concat(label, "') instead of old media.to.").concat(label, " (Probably we'll deprecate it)"));
      return css(_templateObject4(), size, css.apply(void 0, arguments));
    };

    acc.from[label] = function () {
      console.warn("styled-media-query: Use media.greaterThan('".concat(label, "') instead of old media.from.").concat(label, " (Probably we'll deprecate it)"));
      return css(_templateObject5(), size, css.apply(void 0, arguments));
    };

    return acc;
  }, {
    to: {},
    from: {}
  });
  return Object.assign({
    lessThan: lessThan,
    greaterThan: greaterThan,
    between: between
  }, oldStyle);
}
/**
 * Media object with default breakpoints
 * @return {object} - Media generators for each size
 */

var index = generateMedia();
/**
 * Usage: styled.div` ${media.from.medium`background: #000;`} `;
 * With this code, background for small and medium sizes will be `default` and for more than medium, will be `#000`
 */

export default index;
export { pxToEm, pxToRem, defaultBreakpoints, generateMedia };
