'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.space = undefined;

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _util = require('./util');

var _constants = require('./constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var REG = /^[mp][trblxy]?$/;

var space = exports.space = function space(props) {
  var keys = Object.keys(props).filter(function (key) {
    return REG.test(key);
  }).sort();
  var bp = (0, _util.breaks)(props);
  var sc = (0, _util.get)(props, 'theme.space', _constants2.default.space);

  return keys.map(function (key) {
    var val = props[key];
    var p = getProperties(key);

    if (!Array.isArray(val)) {
      return p.reduce(function (a, b) {
        return Object.assign(a, _defineProperty({}, b, mx(sc)(val)));
      }, {});
    }

    return (0, _util.arr)(val).map(mx(sc)).map((0, _util.dec)(p)).map((0, _util.media)(bp)).reduce(_util.merge, {});
  }).reduce(_util.merge, {});
};

var mx = function mx(scale) {
  return function (n) {
    if (!(0, _util.num)(n)) {
      return scale[n] || n;
    }

    var value = scale[Math.abs(n)] || Math.abs(n);
    if (!(0, _util.num)(value)) {
      return (0, _util.neg)(n) ? '-' + value : value;
    }

    return (0, _util.px)(value * ((0, _util.neg)(n) ? -1 : 1));
  };
};

var getProperties = function getProperties(key) {
  var _key$split = key.split(''),
      _key$split2 = _slicedToArray(_key$split, 2),
      a = _key$split2[0],
      b = _key$split2[1];

  var prop = properties[a];
  var dirs = directions[b] || [''];
  return dirs.map(function (dir) {
    return prop + dir;
  });
};

var properties = {
  m: 'margin',
  p: 'padding'
};

var directions = {
  t: ['Top'],
  r: ['Right'],
  b: ['Bottom'],
  l: ['Left'],
  x: ['Left', 'Right'],
  y: ['Top', 'Bottom']
};

var responsive = _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.string, _propTypes2.default.array]);

space.propTypes = {
  m: responsive,
  mt: responsive,
  mr: responsive,
  mb: responsive,
  ml: responsive,
  mx: responsive,
  my: responsive,
  p: responsive,
  pt: responsive,
  pr: responsive,
  pb: responsive,
  pl: responsive,
  px: responsive,
  py: responsive
};

var meta = function meta(prop) {
  return {
    prop: prop,
    responsive: true,
    styleType: 'responsive',
    themeKey: 'space'
  };
};
Object.keys(space.propTypes).forEach(function (prop) {
  space.propTypes[prop].meta = meta(prop);
});

exports.default = space;