'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var breakpoints = ['40em', '52em', '64em'];

var space = [0, 4, 8, 16, 32, 64, 128, 256, 512];

var fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 96, 128];

var theme = {
  breakpoints: breakpoints,
  space: space,
  fontSizes: fontSizes
};

exports.default = theme;