var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import { Component, Children } from 'react';
import PropTypes from 'prop-types';

var I18nextProvider = function (_Component) {
  _inherits(I18nextProvider, _Component);

  function I18nextProvider(props, context) {
    _classCallCheck(this, I18nextProvider);

    var _this = _possibleConstructorReturn(this, (I18nextProvider.__proto__ || Object.getPrototypeOf(I18nextProvider)).call(this, props, context));

    _this.i18n = props.i18n;
    _this.defaultNS = props.defaultNS;
    if (props.initialI18nStore) {
      _this.i18n.services.resourceStore.data = props.initialI18nStore;
      _this.i18n.options.isInitialSSR = true; // if set will be deleted on first render in translate hoc
    }
    if (props.initialLanguage) {
      _this.i18n.changeLanguage(props.initialLanguage);
    }
    _this.reportNS = props.reportNS;
    return _this;
  }

  _createClass(I18nextProvider, [{
    key: 'getChildContext',
    value: function getChildContext() {
      return {
        i18n: this.i18n,
        defaultNS: this.defaultNS,
        reportNS: this.reportNS
      };
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      if (this.props.i18n !== nextProps.i18n) {
        throw new Error('[react-i18next][I18nextProvider]does not support changing the i18n object.');
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var children = this.props.children;

      return Children.only(children);
    }
  }]);

  return I18nextProvider;
}(Component);

I18nextProvider.propTypes = {
  i18n: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  defaultNS: PropTypes.string,
  reportNS: PropTypes.func
};

I18nextProvider.childContextTypes = {
  i18n: PropTypes.object.isRequired,
  defaultNS: PropTypes.string,
  reportNS: PropTypes.func
};

I18nextProvider.defaultProps = {
  defaultNS: undefined,
  reportNS: undefined
};

export default I18nextProvider;