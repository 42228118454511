'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Flex = exports.Box = undefined;

var _systemComponents = require('system-components');

var _systemComponents2 = _interopRequireDefault(_systemComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Box = exports.Box = (0, _systemComponents2.default)({}, {
  boxSizing: 'border-box'
}, 'width', 'space', 'fontSize', 'color', 'flex', 'order', 'alignSelf');

Box.displayName = 'Box';

var Flex = exports.Flex = (0, _systemComponents2.default)({
  is: Box
}, { display: 'flex' }, 'flexWrap', 'flexDirection', 'alignItems', 'justifyContent');

Flex.displayName = 'Flex';