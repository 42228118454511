'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _systemComponents = require('system-components');

var _systemComponents2 = _interopRequireDefault(_systemComponents);

var _Box = require('./Box');

var _Box2 = _interopRequireDefault(_Box);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Flex = (0, _systemComponents2.default)({
  is: _Box2.default
}, { display: 'flex' }, 'flexWrap', 'flexDirection', 'alignItems', 'justifyContent');

Flex.displayName = 'Flex';

exports.default = Flex;