var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getDefaults, getI18n } from './context';

var removedIsInitialSSR = false;

var I18n = function (_Component) {
  _inherits(I18n, _Component);

  function I18n(props, context) {
    _classCallCheck(this, I18n);

    var _this = _possibleConstructorReturn(this, (I18n.__proto__ || Object.getPrototypeOf(I18n)).call(this, props, context));

    _this.i18n = props.i18n || context.i18n || getI18n();

    var i18nOptions = _this.i18n && _this.i18n.options && _this.i18n.options.react || {};
    _this.options = _extends({}, getDefaults(), i18nOptions, props);

    // nextjs SSR: getting data from next.js or other ssr stack
    if (props.initialI18nStore) {
      _this.i18n.services.resourceStore.data = props.initialI18nStore;
      _this.options.wait = false; // we got all passed down already
    }
    if (props.initialLanguage) {
      _this.i18n.changeLanguage(props.initialLanguage);
    }

    // provider SSR: data was set in provider and ssr flag was set
    if (_this.i18n.options && _this.i18n.options.isInitialSSR) {
      _this.options.wait = false;
    }

    var language = _this.i18n.languages && _this.i18n.languages[0];
    var ready = !!language && _this.getNamespaces().every(function (ns) {
      return _this.i18n.hasResourceBundle(language, ns);
    });

    _this.state = {
      i18nLoadedAt: null,
      ready: ready
    };

    _this.t = _this.getI18nTranslate();

    _this.onI18nChanged = _this.onI18nChanged.bind(_this);
    _this.getI18nTranslate = _this.getI18nTranslate.bind(_this);
    _this.namespaces = _this.getNamespaces.bind(_this);
    return _this;
  }

  _createClass(I18n, [{
    key: 'getChildContext',
    value: function getChildContext() {
      return {
        t: this.t,
        i18n: this.i18n
      };
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.loadNamespaces();
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      // Note that dynamically loading additional namespaces after the initial mount will not block rendering – even if the `wait` option is true.
      if (this.props.ns && prevProps.ns !== this.props.ns) this.loadNamespaces();
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      var _this2 = this;

      this.mounted = false;
      if (this.onI18nChanged) {
        if (this.options.bindI18n) {
          var p = this.options.bindI18n.split(' ');
          p.forEach(function (f) {
            return _this2.i18n.off(f, _this2.onI18nChanged);
          });
        }
        if (this.options.bindStore) {
          var _p = this.options.bindStore.split(' ');
          _p.forEach(function (f) {
            return _this2.i18n.store && _this2.i18n.store.off(f, _this2.onI18nChanged);
          });
        }
      }
    }
  }, {
    key: 'onI18nChanged',
    value: function onI18nChanged() {
      if (!this.mounted) return;
      if (!this.state.ready && this.options.omitBoundRerender) return;

      this.t = this.getI18nTranslate();
      this.setState({ i18nLoadedAt: new Date() }); // rerender
    }
  }, {
    key: 'getI18nTranslate',
    value: function getI18nTranslate() {
      return this.i18n.getFixedT(null, this.options.nsMode === 'fallback' ? this.getNamespaces() : this.getNamespaces()[0]);
    }
  }, {
    key: 'getNamespaces',
    value: function getNamespaces() {
      var ns = this.props.ns || this.i18n.options && this.i18n.options.defaultNS;
      return typeof ns === 'string' ? [ns] : ns;
    }
  }, {
    key: 'loadNamespaces',
    value: function loadNamespaces() {
      var _this3 = this;

      var bind = function bind() {
        if (_this3.options.bindI18n && _this3.i18n) _this3.i18n.on(_this3.options.bindI18n, _this3.onI18nChanged);
        if (_this3.options.bindStore && _this3.i18n.store) _this3.i18n.store.on(_this3.options.bindStore, _this3.onI18nChanged);
      };

      this.mounted = true;
      this.i18n.loadNamespaces(this.getNamespaces(), function () {
        var ready = function ready() {
          if (_this3.mounted && !_this3.state.ready) _this3.setState({ ready: true });
          if (_this3.options.wait && _this3.mounted) bind();
        };

        if (_this3.i18n.isInitialized) {
          ready();
        } else {
          var initialized = function initialized() {
            // due to emitter removing issue in i18next we need to delay remove
            setTimeout(function () {
              _this3.i18n.off('initialized', initialized);
            }, 1000);
            ready();
          };

          _this3.i18n.on('initialized', initialized);
        }
      });

      if (!this.options.wait) bind();
    }
  }, {
    key: 'render',
    value: function render() {
      var _this4 = this;

      var children = this.props.children;
      var ready = this.state.ready;


      if (!ready && this.options.wait) return null;

      // remove ssr flag set by provider - first render was done from now on wait if set to wait
      if (this.i18n.options && this.i18n.options.isInitialSSR && !removedIsInitialSSR) {
        removedIsInitialSSR = true;
        setTimeout(function () {
          delete _this4.i18n.options.isInitialSSR;
        }, 100);
      }

      return children(this.t, {
        i18n: this.i18n,
        t: this.t,
        lng: this.i18n.language,
        ready: ready
      });
    }
  }]);

  return I18n;
}(Component);

export default I18n;


I18n.contextTypes = {
  i18n: PropTypes.object
};

I18n.childContextTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object
};