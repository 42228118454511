'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledSystem = require('styled-system');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var funcNames = Object.keys(_styledSystem.styles);
var unique = function unique(arr) {
  var seen = {};
  return arr.concat().filter(function (item) {
    return seen.hasOwnProperty(item) ? false : seen[item] = true;
  });
};
var isPOJO = function isPOJO(n) {
  return (typeof n === 'undefined' ? 'undefined' : _typeof(n)) === 'object' && n !== null && !Array.isArray(n);
};

var dict = Object.keys(_styledSystem.styles).map(function (key) {
  return {
    key: key,
    propNames: Object.keys(_styledSystem.styles[key].propTypes || {})
  };
}).reduce(function (acc, b) {
  var vals = b.propNames.reduce(function (a, name) {
    return _extends({}, a, _defineProperty({}, name, b.key));
  }, {});
  return _extends({}, acc, vals);
}, {});

var getPropKeys = function getPropKeys(defaultProps) {
  return Object.keys(defaultProps || {}).map(function (key) {
    return dict[key];
  }).filter(function (key) {
    return !!key;
  });
};

var getFuncs = function getFuncs(keys) {
  return keys.map(function (f) {
    return _styledSystem.styles[f] || f;
  }).reduce(function (a, f) {
    return Array.isArray(f) ? [].concat(_toConsumableArray(a), _toConsumableArray(f)) : [].concat(_toConsumableArray(a), [f]);
  }, []);
};

var getPropTypes = function getPropTypes(keys) {
  return keys.filter(function (key) {
    return typeof key === 'string';
  }).filter(function (key) {
    return typeof _styledSystem.styles[key] === 'function';
  }).map(function (key) {
    return _styledSystem.styles[key].propTypes || {};
  }).reduce(function (a, propType) {
    return _extends({}, a, propType);
  }, {});
};

var css = function css(props) {
  return props.css;
};

var omit = function omit(obj, blacklist) {
  var next = {};
  for (var key in obj) {
    if (blacklist.indexOf(key) > -1) continue;
    next[key] = obj[key];
  }
  return next;
};

var tag = _react2.default.forwardRef(function (_ref, ref) {
  var _ref$is = _ref.is,
      Tag = _ref$is === undefined ? 'div' : _ref$is,
      _ref$blacklist = _ref.blacklist,
      blacklist = _ref$blacklist === undefined ? [] : _ref$blacklist,
      props = _objectWithoutProperties(_ref, ['is', 'blacklist']);

  return _react2.default.createElement(Tag, _extends({
    ref: ref
  }, omit(props, blacklist)));
});

var System = function System(opts) {
  _classCallCheck(this, System);

  var createComponent = opts.createComponent;


  this.create = function () {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var first = args[0],
        rest = args.slice(1);


    var defaultProps = isPOJO(first) ? first : null;
    var propKeys = getPropKeys(defaultProps);
    var funcsOrKeys = defaultProps ? rest : args;
    var combined = unique([].concat(_toConsumableArray(propKeys), _toConsumableArray(funcsOrKeys)));
    var funcs = getFuncs(combined);
    var propTypes = getPropTypes(combined);

    var blacklist = ['css'].concat(_toConsumableArray(Object.keys(propTypes)), _toConsumableArray(defaultProps ? defaultProps.blacklist || [] : []));

    var Base = defaultProps && typeof defaultProps.is === 'function' ? defaultProps.is : tag;

    var Component = createComponent(Base).apply(undefined, [css].concat(_toConsumableArray(funcs)));

    var baseProps = _styledSystem.util.get(defaultProps, 'is.defaultProps') || {};
    Component.defaultProps = _extends({}, baseProps, {
      blacklist: [].concat(_toConsumableArray(blacklist), _toConsumableArray(baseProps.blacklist || []))
    }, defaultProps);
    Component.propTypes = propTypes;
    Component.systemComponent = true;

    return Component;
  };

  return this.create;
};

exports.default = System;