import get from "lodash/get";

/**
 * Returns the value of `props[path]` or `defaultValue`
 * @example
 * const Button = styled.button`
 *  color: ${prop("color", "red")};
 * `;
 */
var prop = function prop(path, defaultValue) {
  return function () {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return get(props, path, defaultValue);
  };
};

export default prop;