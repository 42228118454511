'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _systemComponents = require('system-components');

var _systemComponents2 = _interopRequireDefault(_systemComponents);

var _theme = require('./theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Box = (0, _systemComponents2.default)({
  theme: _theme2.default
}, {
  boxSizing: 'border-box'
}, 'width', 'space', 'fontSize', 'color', 'flex', 'order', 'alignSelf');

Box.displayName = 'Box';

exports.default = Box;