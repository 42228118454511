var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

import get from "lodash/get";
import at from "lodash/at";
import values from "lodash/values";
import difference from "lodash/difference";


/**
 * Returns `pass` if prop is truthy. Otherwise returns `fail`
 * @example
 * // usage with styled-theme
 * import styled from "styled-components";
 * import { ifProp } from "styled-tools";
 * import { palette } from "styled-theme";
 *
 * const Button = styled.button`
 *   background-color: ${ifProp("transparent", "transparent", palette(0))};
 *   color: ${ifProp(["transparent", "accent"], palette("secondary", 0))};
 *   font-size: ${ifProp({ size: "large" }, "20px", ifProp({ size: "medium" }, "16px", "12px"))};
 * `;
 */
var ifProp = function ifProp(test, pass, fail) {
  return function () {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var result = void 0;
    if (Array.isArray(test)) {
      result = !at(props, test).filter(function (value) {
        return !value;
      }).length;
    } else if (typeof test === "function") {
      result = test(props);
    } else if ((typeof test === "undefined" ? "undefined" : _typeof(test)) === "object") {
      var testKeys = Object.keys(test);
      var testValues = values(test);
      result = !difference(at(props, testKeys), testValues).length;
    } else {
      result = get(props, test);
    }
    var value = result ? pass : fail;
    return typeof value === "function" ? value(props) : value;
  };
};

export default ifProp;